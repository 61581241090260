<template>
  <div>
    <CRow>
      <CCol sm="12">
        <p>
          Carga aqui las medallas a asignar a los usuarios, recuerda poner el id de la medalla a subir.
        </p>
        <form @submit.prevent="submit">
          <input type="file" @change="handleFileUpload( $event )">
          <br><br>
          <CButton
            @click="send()"
            color="primary"
          >
            <CSpinner v-if="loading" variant="grow" size="sm" />
            Enviar
          </CButton>
        </form>
        <a :href="urlBase+'/invoices/badgeuser.xlsx'">
          Descargar formato
        </a>
      </CCol>
      
      <CToaster :autohide="3000">
        <template>
          <CToast
            v-for="(item, index) in toasts"
            :key="`${item.title}-${index}`"
            :show="true"
            :color="item.color"
            :header="item.title"
          >
            {{ item.message }}
          </CToast>
        </template>
      </CToaster>
    </CRow>

    <CCardBody>
      <div class="text-center">
        <CSpinner v-if="loading" variant="grow" size="lg" />
      </div>

      <CDataTable
        v-if="!loading"
        hover
        striped
        sorter
        :items="items"
        :fields="fields"
      >
        <template #user="data">
          <td>
            {{ data.item.user.email }}
          </td>
        </template>
        <template #badge="data">
          <td>
            {{ data.item.badge.name }}
          </td>
        </template>
        <template #used="data">
          <td>
            {{ data.item.liquidation_m_id || data.item.liquidation_id ? "Si" : "No" }}
          </td>
        </template>
        <template #expiration="data">
          <td>
            {{ data.item.expiration | dateFormat }}
          </td>
        </template>
        <template #action="data">
          <td>
            <CButton
              class="float-right"
              color="danger"
              @click="deleteRow(data.item.id)"
              v-if="!(data.item.liquidation_m_id || data.item.liquidation_id)"
            >
              <font-awesome-icon icon="trash" />
            </CButton>
          </td>
        </template>
      </CDataTable>

      <CPagination
        v-if="!loading"
        :activePage.sync="page"
        :pages="lastPage"
        size="sm"
        align="center"
        @update:activePage="pageChange"
      />
    </CCardBody>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Resource from "@/api/resource";
const badgeuserResource = new Resource("badgeusers");

export default {
  name: 'Import',
  data(){
    return {
      file: '',
      toasts: [],
      loading: false,
      urlBase: process.env.VUE_APP_BACKEND_URL,
      items: [],
      fields: [
        {
          key: "user",
          label: "Usuario",
          sorter: true,
          filter: true,
        },
        {
          key: "badge",
          label: "Medalla",
          sorter: true,
          filter: true,
        },
        { key: "used", label: "Usada", sorter: false, filter: true },
        { key: "expiration", label: "Expira", sorter: true, filter: true },
        { key: "action", label: "", sorter: false, filter: false },
      ],
      page: 1,
      perPage: 10,
      lastPage: 1,
    }
  },
  filters: {
    dateFormat: function (value) {
      return value ? moment(value).format("YYYY-MM-DD HH:mm") : "";
    },
  },
  mounted() {
    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    this.getItems();
  },
  methods: {
    handleFileUpload( event ){
      this.file = event.target.files[0];
    },
    async send() {
      this.loading = true;
      let formData = new FormData();
      formData.append('file', this.file);
      await axios.post( process.env.VUE_APP_BACKEND_URL+'/api/badgeusers/import',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Accept':'application/json',
              'Authorization': `Bearer `+ this.$store.state.user.token
          }
        }
      ).then((data) => {
        console.log(data);
        this.loading = false;
        this.getItems();
        this.toasts.push({
            title: "Confirmación",
            color: "success",
            message: "Medallas registrados con exito",
          });
      })
      .catch((error) => {
        this.loading = false;
        var errors = error.response.data.errors;
        Object.keys(errors).forEach((key) => {
          error = {
            title: "Error al registrar medallas",
            color: "danger",
            message: errors[key],
          };
        });
        this.toasts.push(error);
      });
    },
    async pageChange(val) {
      this.$router.push({ query: { page: val } });
      await this.getItems();
      this.page = val;
    },
    async getItems() {
      this.loading = true;
      try {
        const response = await badgeuserResource.list({
          page: this.page,
          perPage: this.perPage,
        });
        if (response.status == 200) {
          this.items = response.data.data;
          this.lastPage = response.data.last_page;
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    deleteRow(id){
      this.loading = true;
      badgeuserResource.destroy(id).then(response => {
        this.getItems();
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
